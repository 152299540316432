import React from "react";
import { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useParallax } from "hooks";
import {
  CjsJumbotron,
  InteractionCard,
  Stack,
  QuoteButton,
  ServicesSection,
  PageHelmet,
} from "components";

import boomLift from "images/crane-boom2-small.jpg";
import ltm from "images/LTM-Background-Small.jpeg";
import cjsGuy from "images/CJS-Guy.png";

import "./home.scss";

const Home = () => {
  const fullWidthElement = useRef<HTMLDivElement | null>(null);
  useParallax(fullWidthElement.current, true);

  return (
    <Container fluid>
      <PageHelmet
        title="CJ Shaughnessy Crane"
        description="CJ Shaughnessy Crane Service is the trusted mobile crane and rigging provider for greater Boston."
      />
      <CjsJumbotron />
      <Container className="page-container">
        <ServicesSection />
        <div className="fullwidth-row home-row" ref={fullWidthElement}>
          <div className="fullwidth-overlay"></div>
          <Row className="row quote-row">
            <Col>
              <div className="quote-wrapper">
                <h3>Let's find the right equipment for your job!</h3>
                <p>We're ready to get started.</p>
                <QuoteButton />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="home-row">
          <Col>
            <h3
              className="text-primary"
              style={{ marginBottom: "0", fontWeight: "bold" }}
            >
              EXPLORE OUR SITE
            </h3>
            <p className="sub-header">projects - history - equipment</p>
            <p className="description-info">
              Stick around and check out some of our other pages for more
              information!
            </p>
            <Stack
              direction="row"
              space={30}
              sx={{ marginLeft: "auto", marginRight: "auto", padding: "2rem" }}
            >
              <div>
                <InteractionCard
                  title="EXPLORE OUR RECENT LIFTS"
                  href="/work"
                  imgAlt="CJS Crane Boom Lift"
                  imgUrl={boomLift}
                />
              </div>
              <div>
                <InteractionCard
                  title="BROWSE OUR EQUIPMENT"
                  href="/equipment"
                  imgAlt="CJS Crane LTM Crane"
                  imgUrl={ltm}
                />
              </div>
              <div>
                <InteractionCard
                  title="LEARN ABOUT US AND OUR HISTORY"
                  href="/about"
                  imgAlt="CJS Crane Guy Logo"
                  imgUrl={cjsGuy}
                />
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Home };
