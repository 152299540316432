import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { Stack } from "../Stack";

import cjsLogo from "../../images/cjs-logo-trim.png";

import "./Footer.scss";

const Footer = () => {
  return (
    <Container fluid className="fluid-footer">
      <Container>
        <Row style={{ paddingTop: "20px" }}>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <img alt="cjs logo" className="cjs-logo" src={cjsLogo} />
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <h5>
                <Link className="quote" to="/contact">
                  Contact Us
                </Link>
              </h5>
              <Row>
                <Col>
                  <Stack direction="column" space={10}>
                    <Link className="quote" to="/quote">
                      Get A Quote
                    </Link>
                    <Link className="quote" to="/contracts">
                      Contracts
                    </Link>
                    <Link className="quote" to="/careers">
                      Careers
                    </Link>
                    <a className="phone" href="tel:+1-781-315-5321">
                      781.315.5321
                    </a>
                    <a
                      className="email"
                      href="mailto:cjshaughnessycrane@gmail.com"
                    >
                      cjshaughnessycrane@gmail.com
                    </a>
                    <a
                      className="address"
                      href="https://maps.google.com/?q=520 Bodwell St, Avon, MA 02322"
                    >
                      520 Bodwell St, Avon, MA 02322
                    </a>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <Row>
                <Col xs={12}>
                  <div>
                    <h6 style={{ color: "#fff" }}>Follow Us</h6>
                    <a
                      title="facebook page"
                      className="logo facebook-anchor"
                      href="https://www.facebook.com/Cjshaughnessycrane/"
                    ></a>
                    <a
                      title="instagram page"
                      className="logo instagram-anchor"
                      href="https://www.instagram.com/cshaughnessy1/"
                    ></a>
                    <a
                      title="linkedin page"
                      className="logo linkedin-anchor"
                      href="https://www.linkedin.com/company/cj-shaughnessy-crane-service/mycompany/?viewAsMember=true"
                    ></a>
                  </div>
                </Col>
                <Col xs={12}>
                  <h6>Proud Member of Local 4</h6>
                  {/* <img alt="local-4" src={localFourLogo} /> */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Footer };
