import React from "react";
import { useState } from "react";
import {
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Row,
  Col,
} from "react-bootstrap";
import { motion } from "framer-motion";

import {
  CjsJumbotron,
  EquipmentCard,
  Tiles,
  Stack,
  PageHelmet,
} from "components";
import { Equipment as IEquipment } from "types";

import { cranes, trucks, telehandlers } from "./equipmentList";

import "./equipment.scss";

const renderCraneCard = (crane: IEquipment, index: number) => {
  return (
    <motion.div
      key={`motion-${index}-${crane.model}`}
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      transition={{
        duration: 0.75,
        type: "spring",
        bounce: 0.25,
        stiffness: 50,
      }}
    >
      <EquipmentCard equipment={crane} />
    </motion.div>
  );
};

const Equipment = () => {
  const [equipment, setEquipment] = useState<IEquipment[]>(cranes);

  const handleOnChange = (value: string) => {
    if (value === "cranes") {
      setEquipment(cranes);
      return;
    } else if (value === "trucks") {
      setEquipment(trucks);
      return;
    }
    setEquipment(telehandlers);
  };

  return (
    <Container fluid>
      <PageHelmet
        title="Equipment - CJ Shaughnessy Crane"
        description="Check out a detailed list of our mobile cranes and trucks. Download load charts and request a specific crane or truck for your job here!"
      />
      <CjsJumbotron
        header="OUR EQUIPMENT"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Stack direction="column" space={30} sx={{ marginBottom: "30px" }}>
          <div>
            <Row>
              <Col xs={12} md={8}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  BEST IN CLASS
                </h3>
                <p className="sub-header">LIEBHERR - PETERBILT - KENWORTH </p>
                <p className="description-info">
                  At CJ Shaughnessy Crane Service, we believe in running with
                  the industry's best equipment. This allows us to take on
                  challenging work with ease while maintaing our core ethos of
                  safety.
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <ToggleButtonGroup
                type="radio"
                name="options"
                onChange={handleOnChange}
                defaultValue="cranes"
              >
                <ToggleButton
                  variant="outline-primary"
                  type="radio"
                  name="cranes"
                  value="cranes"
                >
                  Cranes
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  type="radio"
                  name="trucks"
                  value="trucks"
                >
                  Trucks
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  type="radio"
                  name="telehandlers"
                  value="telehandlers"
                >
                  Telehandlers
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>

          <Row bsPrefix="row d-sm-flex d-lg-none">
            <Stack direction="row" space={20} sx={{ padding: "10px" }}>
              {equipment.map(renderCraneCard)}
            </Stack>
          </Row>

          <Row
            bsPrefix="row d-none d-lg-flex"
            style={{
              marginBottom: "50px",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <Tiles columns={3} space="25px">
              {equipment.map(renderCraneCard)}
            </Tiles>
          </Row>
        </Stack>
      </Container>
    </Container>
  );
};

export { Equipment };
