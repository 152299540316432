import * as React from "react";
import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { ApplicationData } from "../types";

interface ApplicationFormProps {
  onSubmit: (values: ApplicationData) => void;
}

const initialValue: ApplicationData = {
  name: "",
  email: "",
  phoneNumber: "",
  role: "Crane Operator",
  resume: null,
  comments: "",
};

const ApplicationForm = ({ onSubmit }: ApplicationFormProps) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<ApplicationData>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setFormData({
        ...formData,
        [event.target.name]: null,
      });
      return;
    }

    const file = event.target.files[0];
    setFormData({
      ...formData,
      [event.target.name]: file,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    console.log(formData);

    if (form.checkValidity()) {
      onSubmit(formData);
    }
  };

  return (
    <div>
      <Form
        id={"application-form"}
        onSubmit={handleSubmit}
        validated={validated}
        noValidate
      >
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                maxLength={200}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your name!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                maxLength={200}
                name="email"
                type="email"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a contact email!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formGridRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                required
                name="role"
                as="select"
                defaultValue="Choose..."
                onChange={handleChange}
              >
                <option value="Crane Operator">Crane Operator</option>
                <option value="Mechanic">Mechanic</option>
                <option value="Commercial Driver">Commercial Driver</option>
                <option value="Riger">Rigger</option>
                <option value="Other">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please choose a role to apply for!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formResume">
              <Form.Label>Resume</Form.Label>
              <Form.Control
                required
                name="resume"
                type="file"
                accept=".pdf,.PDF"
                onChange={handleFileChange}
              />
              <Form.Control.Feedback type="invalid">
                Please attach a PDF resume!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="comments">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                type="text"
                maxLength={400}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { ApplicationForm };
