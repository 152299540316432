import { Equipment as IEquipment, EquipmentType } from "../../types";

import LtmBig from "../../images/LTM-BigScaled.png";
import Ltm1650 from "../../images/LTM-1650-8-1.png";
import Ltm1200 from "../../images/LTM-1200-5-1.png";
import Ltm1100 from "../../images/LTM-1100-4-2.png";
import Ltm1120 from "../../images/LTM-1120-4-1.png";
import Ltm1060 from "../../images/LTM-1060-3-1.png";
import Manitex from "../../images/Manitex-40124.png";
import Truck from "../../images/Truck.png";
import Peterbilt from "../../images/Peterbilt-389.png";
import Xtreme from "../../images/Xtreme-XR-1570C.png";
import Manitou from "../../images/Manitou-MHT-10180.png";

const cranes: IEquipment[] = [
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1650-8.1",
    serviceDate: "Mobile Crane",
    imgSrc: Ltm1650,
    loadChartUrl: "/loadCharts/ltm-1650-8-1.pdf",
    specs: [
      { description: "Load Capacity", value: "770 Tons" },
      { description: "Axles", value: "8" },
      { description: "Engine", value: "686 HP" },
      { description: "Boom Height", value: "263 ft" },
      { description: "Max Radius", value: "370 ft" },
      { description: "Hoist Height", value: "499 ft" },
    ],
    quantity: 1,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1450-8.1",
    serviceDate: "Mobile Crane",
    imgSrc: LtmBig,
    loadChartUrl: "/loadCharts/ltm-1450-8-1.pdf",
    specs: [
      { description: "Load Capacity", value: "550 Tons" },
      { description: "Axles", value: "8" },
      { description: "Engine", value: "686 HP" },
      { description: "Boom Height", value: "279 ft" },
      { description: "Max Radius", value: "355 ft" },
      { description: "Hoist Height", value: "433 ft" },
    ],
    quantity: 1,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1200-5.1",
    serviceDate: "Mobile Crane",
    imgSrc: Ltm1200,
    loadChartUrl: "/loadCharts/ltm-1200-5-1.pdf",
    specs: [
      { description: "Load Capacity", value: "240 Tons" },
      { description: "Axles", value: "5" },
      { description: "Engine", value: "503 HP" },
      { description: "Boom Height", value: "236 ft" },
      { description: "Max Radius", value: "282 ft" },
      { description: "Hoist Height", value: "364 ft" },
    ],
    quantity: 1,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1120-4.1",
    serviceDate: "Mobile Crane",
    imgSrc: Ltm1120,
    loadChartUrl: "/loadCharts/ltm-1120-4-1.pdf",
    specs: [
      { description: "Load Capacity", value: "135 Tons" },
      { description: "Axles", value: "4" },
      { description: "Engine", value: "449 HP" },
      { description: "Boom Height", value: "217 ft" },
      { description: "Max Radius", value: "210 ft" },
      { description: "Hoist Height", value: "299 ft" },
    ],
    quantity: 1,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1100-4.2",
    serviceDate: "Mobile Crane",
    imgSrc: Ltm1100,
    loadChartUrl: "/loadCharts/ltm-1100-4-2.pdf",
    specs: [
      { description: "Load Capacity", value: "120 Tons" },
      { description: "Axles", value: "4" },
      { description: "Engine", value: "476 HP" },
      { description: "Boom Height", value: "197 ft" },
      { description: "Max Radius", value: "190 ft" },
      { description: "Hoist Height", value: "299 ft" },
    ],
    quantity: 2,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Liebherr",
    model: "LTM 1060-3.1",
    serviceDate: "Mobile Crane",
    imgSrc: Ltm1060,
    loadChartUrl: "/loadCharts/ltm-1060-3-1.pdf",
    specs: [
      { description: "Load Capacity", value: "70 Tons" },
      { description: "Axles", value: "3" },
      { description: "Engine", value: "367 HP" },
      { description: "Boom Height", value: "158 ft" },
      { description: "Max Radius", value: "155 ft" },
      { description: "Hoist Height", value: "207 ft" },
    ],
    quantity: 4,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Manitex",
    model: "35124 C",
    serviceDate: "Boom Truck",
    imgSrc: Manitex,
    loadChartUrl: "/loadCharts/manitex-35124-C.pdf",
    specs: [
      { description: "Load Capacity", value: "35 Tons" },
      { description: "Axles", value: "3" },
      { description: "Engine", value: "350 HP" },
      { description: "Boom Height", value: "158 ft" },
      { description: "", value: "" },
      { description: "", value: "" },
    ],
    quantity: 1,
  },
  {
    type: EquipmentType.Crane,
    manufacturer: "Manitex",
    model: "40124 SHL",
    serviceDate: "Boom Truck",
    imgSrc: Manitex,
    loadChartUrl: "/loadCharts/manitex-40124-SHL.pdf",
    specs: [
      { description: "Load Capacity", value: "40 Tons" },
      { description: "Axles", value: "3" },
      { description: "Engine", value: "450 HP" },
      { description: "Boom Height", value: "197 ft" },
      { description: "", value: "" },
      { description: "", value: "" },
    ],
    quantity: 1,
  },
];

const trucks: IEquipment[] = [
  {
    type: EquipmentType.Truck,
    manufacturer: "Peterbilt",
    model: "389",
    serviceDate: "2015",
    imgSrc: Peterbilt,
    specs: [
      { description: "Towing Capacity", value: "10 Tons" },
      { description: "Axles", value: "3" },
    ],
  },
  {
    type: EquipmentType.Truck,
    manufacturer: "Peterbilt",
    model: "389",
    serviceDate: "2018",
    imgSrc: Peterbilt,
    specs: [
      { description: "Towing Capacity", value: "10 Tons" },
      { description: "Axles", value: "3" },
    ],
  },
  {
    type: EquipmentType.Truck,
    manufacturer: "Kenworth",
    model: "W900",
    serviceDate: "2020",
    imgSrc: Truck,
    specs: [
      { description: "Towing Capacity", value: "10 Ton" },
      { description: "Axles", value: "3" },
    ],
  },
];

const telehandlers: IEquipment[] = [
  {
    type: EquipmentType.Telehandler,
    manufacturer: "Lull",
    model: "1044C-54",
    serviceDate: "2019",
    imgSrc: Xtreme,
    specs: [
      { description: "Capacity", value: "10,000 lbs" },
      { description: "Lift Height", value: "54 ft" },
    ],
  },
  {
    type: EquipmentType.Telehandler,
    manufacturer: "Xtreme",
    model: "XR1570-C",
    serviceDate: "2019",
    imgSrc: Xtreme,
    specs: [
      { description: "Capacity", value: "15,000 lbs" },
      { description: "Lift Height", value: "70 ft" },
    ],
  },
  {
    type: EquipmentType.Telehandler,
    manufacturer: "Manitou",
    model: "MHT 10180",
    serviceDate: "2022",
    imgSrc: Manitou,
    specs: [
      { description: "Capacity", value: "40,000 lbs" },
      { description: "Lift Height", value: "30 ft" },
    ],
  },
];

export { cranes, trucks, telehandlers };
